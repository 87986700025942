import firebase from 'firebase/app'
import store from '@/store'

export default async function updateUserData(payload) {
  const { data } = payload
  try {
    const uid = store.getters['userBasic/uid']
    data.updateTime = new Date()
    await firebase.firestore().collection('user').doc(uid).set(data, { merge: true })
  } catch (error) {
    window.console.log('error', error)
  }
}
