<template>
  <div>
    <h2>購入履歴</h2>
    <b-card
      title=""
    >
      <div
        v-if="data.length === 0"
      >
        <b-row class="ml-1">
          表示できるデータはありません。
        </b-row>
      </div>
      <div
        v-else
      >
        <BasicTable
          :value="data"
          :header="fields"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard, BRow,
} from 'bootstrap-vue'
import dateformate from '@/components/js/filter/formatDate'
import BasicTable from '@/components/ui/table/BasicTable.vue'
import fields from '@/components/conf/student/学習PF_受講生_購入済みコイン一覧ヘッダー.json'
import makeTable from '@/components/student/payhistory/js/TableData'
import updateUserData from '@/firestore/data/updateUser'

export default {
  components: {
    BCard,
    BRow,
    BasicTable,
  },
  directives: {
    Ripple,
  },
  filters: {
    dateText(val) {
      return dateformate(val)
    },
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields,
      data: [],
    }
  },
  watch: {
    dataList: {
      async handler() {
        const d = {
          data: this.dataList,
        }
        const response = await makeTable(d)
        if (response.status === 'success') {
          this.data = response.data
          window.console.log('memo', response.sum)
          this.$store.dispatch('userBasic/updateCoin', response.sum)
          this.updateDB(Response.sum)
        } else {
          window.console.log('error', response.msg)
        }
      },
    },
  },
  methods: {
    updateDB(val) {
      // Firestoreへの更新
      if (val) {
        const dd = {
          coin: val,
        }
        updateUserData({
          data: dd,
        })
      }
    },
  },
}
</script>
