<template>
  <div>
    <h2>コイン</h2>
    <b-card
      title=""
    >
      <b-row>
        <div class="ml-1">
          現在の保有コイン：
          <span
            v-if="Number(coin) >= 0"
            class="coin_"
          >
            {{ Number(coin).toLocaleString() }}
          </span>コイン
        </div>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="mt-1"
            @click="add"
          >
            新規購入
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  data() {
    return {
      userData: {},
    }
  },
  computed: {
    ...mapGetters('userBasic', ['coin']),
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
  },
  methods: {
    add() {
      this.$router.push({ path: '/student-pay-edit' })
    },
  },
}
</script>

<style scoped>
.coin_ {
  font-size: 2.4rem;
  font-weight: 600;
}
</style>
