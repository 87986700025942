import dateformate from '@/components/js/filter/formatDate'

export default function makeTable(payload) {
  try {
    const { data } = payload
    const dataList = []
    let sum = 0
    /* eslint-disable */
    for (const i in data) {
      const item = data[i]
      const d = {
        status: '確認中',
        date: dateformate(item.insertTime),
        coinLabel: item.coinLabel,
        price: item.priceLabel,
      }
      dataList.push(d)
      sum += Number(item.coin)
    }
    /* eslint-enable */

    const result = {
      status: 'success',
      data: dataList,
      sum,
    }
    return result
  } catch (error) {
    const result = {
      status: 'error',
      msg: error,
    }
    return result
  }
}
