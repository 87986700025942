<template>
  <b-row class="match-height">
    <b-col lg="8">
      <ListPay />
    </b-col>
    <b-col lg="8">
      <ListPayhistory :data-list="payHistoryList" />
    </b-col>
    <b-col lg="4">
      <Sidebar />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ListPay from '@/components/student/pay/ListPay.vue'
import ListPayhistory from '@/components/student/payhistory/ListPayhistory.vue'
import getDataListOrderByInsertTimeDesc from '@/firestore/data/getListTimeDesc'
import Sidebar from '@/components/student/sidebar/test/Sidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    ListPay,
    ListPayhistory,
    Sidebar,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('payBasic', ['payHistoryList']),
  },
  async mounted() {
    this.$store.dispatch('payBasic/updateList', [])
    const data = {
      collection: 'pay',
    }
    const response = await getDataListOrderByInsertTimeDesc(data)
    if (response.status === 'success') {
      this.$store.dispatch('payBasic/updateList', response.data)
    } else {
      window.console.log('error', response.msg)
    }
  },
}
</script>

<style lang="scss">
</style>
